<template>
  <va-card>
    <va-card-title>{{ $t('product.detailsTitle') }}</va-card-title>
    <va-card-content>
      <div v-if="product">
        <div class="attribute-row">
          <span class="attribute-name">{{ $t('product.id') }}:</span>
          <span class="attribute-value">{{ product.id }}</span>
        </div>
        <div class="attribute-row">
          <span class="attribute-name">{{ $t('product.name') }}:</span>
          <span class="attribute-value">{{ product.name }}</span>
        </div>
        <div class="attribute-row">
          <span class="attribute-name">{{ $t('product.type') }}:</span>
          <span class="attribute-value">{{ product.type }}</span>
        </div>
        <div class="attribute-row">
          <span class="attribute-name">{{ $t('product.seatLimitation') }}:</span>
          <span class="attribute-value">{{ product.seatLimitation }}</span>
        </div>
        <div class="attribute-row" v-if="product.usageCounter">
          <span class="attribute-name">{{ $t('product.usageCounterUnit') }}:</span>
          <span class="attribute-value">{{ product.usageCounter.unit }}</span>
        </div>
        <div class="attribute-row" v-if="product.usageCounter">
          <span class="attribute-name">{{ $t('product.usageCounterDescription') }}:</span>
          <span class="attribute-value">{{ product.usageCounter.description }}</span>
        </div>
        <div class="attribute-row">
          <span class="attribute-name">{{ $t('product.licenseSystemType') }}:</span>
          <span class="attribute-value">{{ product.licenseSystemType || 'N/A' }}</span>
        </div>
        <div v-if="product.attributes">
          <va-divider />
          <va-card-title>{{ $t('product.attributesTitle') }}</va-card-title>
          <div v-for="(value, key) in product.attributes" :key="key" class="attribute-row">
            <span class="attribute-name">{{ $t(`product.${key}`) || key }}:</span>
            <span class="attribute-value">{{ value }}</span>
          </div>
        </div>
      </div>
    </va-card-content>
  </va-card>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";

const store = useStore();
const { t } = useI18n();

const product = computed(() => store.state.licensing.productEdit);
</script>

<style scoped>
.attribute-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.attribute-name {
    font-size: 0.9em;
    font-weight: 700;
    color: var(--va-gray);
    text-transform: uppercase;
}

.attribute-value {
    font-family: monospace;
    font-size: 0.9rem;
}
</style>
