<template>
  <div v-if="product">
    <the-header-card @refresh="loadProductDetails">
      <va-button @click="goBack" >
        Back
      </va-button>
    </the-header-card>
    <product-details-tile />
  </div>
  <div v-else>
    <loading-spinner />
  </div>
</template>

<script setup>
import { reactive, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import TheHeaderCard from '@/components/TheHeaderCard.vue';
import ProductDetailsTile from '@/components/products/ProductDetailsTile.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

const route = useRoute();
const router = useRouter();
const store = useStore();

const product = computed(() => store.state.licensing.productEdit);

const state = reactive({
  loading: false,
});

const loadProductDetails = async () => {
  state.loading = true;
  const productId = route.params.id;
  try {
    await store.dispatch('licensing/getProduct', productId);
  } catch (error) {
    console.error('Failed to fetch product details:', error);
  }
  state.loading = false;
};

onMounted(() => {
  loadProductDetails();
});
const goBack = () => {
  router.back();
};
</script>

<style scoped>
.ml-2 {
  margin-left: 0.5rem;
}
</style>
